import { Col, Container, Row } from "react-bootstrap";
import wspIcon from "../../assets/contact/wspicon.png";
import bgMobile from "../../assets/contact/bgMobile.png";
import * as ReactTranslated from "react-translated";

const ContactView = () => {
  return (
    <Container fluid className="box-contact">
      <Container className="h-100">
        <Row className="h-100">
          <Col
            md={6}
            xs={12}
            className="d-md-flex justify-content-center align-content-center align-items-center flex-column  pb-md-0 pb-5"
          >
            <div>
              <div className="pb-4 text-md-start text-center  pt-md-0 pt-5">
                <h1>
                  <ReactTranslated.Translate text="titleGetin" /> <br className="d-md-block d-none" />
                  <span>
                    <ReactTranslated.Translate text="titleGetintouch" />
                  </span>
                </h1>
              </div>

              <div className="pb-5 text-md-start text-center">
                <h2>
                  <ReactTranslated.Translate text="contenidoGetintouch" />
                </h2>
              </div>

              <Row>
                <Col md={6} xs={12}>
                  {/* <h3>Chat with us</h3>
                  <h4 className="d-flex justify-content-start align-content-center align-items-center flex-row">
                    <a
                      href="#"
                      className="link-default d-flex justify-content-center align-content-center align-items-center flex-row"
                    >
                      <div>
                        <img src={wspIcon} alt="Whatsapp" className="me-2" />
                      </div>
                      <div className="pt-1">+54 11 123456789</div>
                    </a>
                  </h4> */}
                  <h3>Email</h3>
                  <h4>
                    <a href="mailto:info@wopdigital.com.ar">info@wopdigital.com.ar</a>
                  </h4>
                </Col>
                <Col md={6} xs={12}>
                  <h3>
                    {" "}
                    <ReactTranslated.Translate text="titlefindUs" />
                  </h3>
                  <h4>Basavilbaso 1350 - Of. 603 CABA - Buenos Aires</h4>
                  <h3>
                    {" "}
                    <a
                      href="https://goo.gl/maps/yjkDZxsuR9Kx6bWZ6"
                      className="link-default fw-normal"
                      style={{ color: "#000" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Google Maps +
                    </a>
                  </h3>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>{" "}
      <Row className="d-md-none d-block">
        <Col className="text-end m-0 p-0">
          <img src={bgMobile} alt="WOP" className="w-100 img-fluid" />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactView;
