import { Row, Col, Container } from "react-bootstrap";
import ProfileItem from "./ProfileItem";
import pablodifilippo from "../../../assets/team/profiles/pablodifilippo.png";
import pabloAleman from "../../../assets/team/profiles/pabloAleman.png";
import emilianoCubisino from "../../../assets/team/profiles/emilianoCubisino.png";
import yamileMasmut from "../../../assets/team/profiles/yamileMasmut.png";
import damianConstantino from "../../../assets/team/profiles/damianConstantino.png";
import cristianMarucci from "../../../assets/team/profiles/cristianMarucci.png";
import fernandoRovaletti from "../../../assets/team/profiles/fernandoRovaletti.png";
import pabloAlvarez from "../../../assets/team/profiles/pabloAlvarez.png";
import soledadQuijano from "../../../assets/team/profiles/soledadQuijano.png";
import martinTonelli from "../../../assets/team/profiles/martinTonelli.png";
import ginoGentile from "../../../assets/team/profiles/ginoGentile.png";
import mauroZicarelli from "../../../assets/team/profiles/mauroZicarelli.png";
import julianGaleano from "../../../assets/team/profiles/julianGaleano.png";

const profiles = [
  {
    img: pablodifilippo,
    name: "Pablo Di Filippo",
    title: "Founder & Director",
    link: "https://www.linkedin.com/in/pablodif/",
  },
  {
    img: pabloAleman,
    name: "Pablo Aleman",
    title: "Business Development",
    link: "https://www.linkedin.com/in/pablo-fernando-alem%C3%A1n/",
  },
  {
    img: yamileMasmut,
    name: "Yamile Masmut",
    title: "Growth & Business Developer",
    link: "https://www.linkedin.com/in/yamile-masmut/",
  },
  {
    img: emilianoCubisino,
    name: "Emiliano Cubisino",
    title: "Senior Designer",
    link: "https://www.linkedin.com/in/emiliano-cubisino-897b00242/",
  },
  {
    img: damianConstantino,
    name: "Damián Costantino",
    title: "Senior UX-UI Designer",
    link: "https://www.linkedin.com/in/dami%C3%A1n-costantino-211140113/",
  },
  {
    img: cristianMarucci,
    name: "Cristian Marucci",
    title: "Head of Development\n\r Avradev\n\r - Wop Partner -",
    link: "https://www.linkedin.com/in/cristianmarucci/",
  },
  {
    img: fernandoRovaletti,
    name: "Fernando Rovaletti",
    title: "Creative Director\n\r Tanque Design\n\r - Wop Partner -",
    link: "#",
  },
  {
    img: pabloAlvarez,
    name: "Pablo Álvarez",
    title: "Art Director\n\r Tanque Design\n\r - Wop Partner -",
    link: "https://www.linkedin.com/in/pabloalvarezespin/",
  },
  {
    img: soledadQuijano,
    name: "Soledad Quijano",
    title: "Press & PR\n\r Partner Press\n\r - Wop Partner -",
    link: "https://www.linkedin.com/in/soledadquijano",
  },
  {
    img: martinTonelli,
    name: "Martín Tonelli",
    title: "Digital Analytics\n\r - Wop Partner -",
    link: "https://www.linkedin.com/in/martintonelli",
  },
  { img: ginoGentile, name: "Gino Gentile", title: "Campaigns", link: "https://www.linkedin.com/in/gino-gentile" },
  {
    img: mauroZicarelli,
    name: "Mauro Zicarelli",
    title: "Business Strategy",
    link: "https://www.linkedin.com/in/maurozicarelli",
  },
  {
    img: julianGaleano,
    name: "Julián Galeano",
    title: "Audiovisual Producer\n\r Praset\n\r - Wop Partner -",
    link: "https://www.linkedin.com/in/juliangaleanook",
  },
];

const ProfileList = () => {
  return (
    <Container>
      <Row>
        <Col md={8} xs={12} className="pb-5 mx-auto">
          <Row className="d-flex justify-content-center">
            {profiles &&
              profiles.length > 0 &&
              profiles.map((v) => (
                <Col md={3} xs={6}>
                  <ProfileItem img={v.img} name={v.name} title={v.title} link={v.link} />{" "}
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileList;
