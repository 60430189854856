import { Col, Container, Row } from "react-bootstrap";
import whoImg from "../../../assets/about/who.png";
import howImg from "../../../assets/about/how.png";
import whatImg from "../../../assets/about/what.png";
import whoImg_es from "../../../assets/about/who_es.png";
import howImg_es from "../../../assets/about/how_es.png";
import whatImg_es from "../../../assets/about/what_es.png";
import foto1 from "../../../assets/about/foto1.png";
import foto2 from "../../../assets/about/foto2.png";
import foto3 from "../../../assets/about/foto3.png";
import foto4 from "../../../assets/about/foto4.png";
import foto5 from "../../../assets/about/foto5.png";
import tanque from "../../../assets/about/tanque.png";
import avra from "../../../assets/about/avra.png";
import venture from "../../../assets/about/venture.png";
import tokyo from "../../../assets/about/tokyo.png";
import amblagar from "../../../assets/about/amblagar.png";
import deva from "../../../assets/about/deva.png";
import sc from "../../../assets/about/sc.png";
import praset from "../../../assets/about/praset.png";
import { Link } from "react-router-dom";
import * as ReactTranslated from "react-translated";
import { UserProvider } from "../../../router/AppProvider";
import { useContext } from "react";

const WhoWeAre = () => {
  const { language } = useContext(UserProvider);
  return (
    <Container>
      <Row className="boxWhoWheAre">
        <Col md={6} xs={11} className="d-flex justify-content-center align-content-center align-items-center mx-auto">
          <div>
            {language === "en" ? (
              <img src={whoImg} className="img-fluid" />
            ) : (
              <img src={whoImg_es} className="img-fluid" />
            )}
          </div>
        </Col>
        <Col
          md={6}
          xs={11}
          className="d-flex flex-column justify-content-center justify-content-start mx-auto pt-md-0 pt-5"
        >
          <div>
            <p className="lightTxt">
              <ReactTranslated.Translate text="contenidoWhoweare" />
            </p>
          </div>
          <div className="pt-3 pb-3">
            <Link to="/team" className="link-default">
              <ReactTranslated.Translate text="contenidoWhoweare2" />
            </Link>
          </div>
        </Col>
      </Row>

      <Row className="boxWhoWheAre">
        <Col md={6} xs={11} className="d-flex justify-content-center align-content-center align-items-center mx-auto">
          <div>
            {language === "en" ? (
              <img src={whatImg} className="img-fluid" />
            ) : (
              <img src={whatImg_es} className="img-fluid" />
            )}
          </div>
        </Col>
        <Col
          md={6}
          xs={11}
          className="d-flex flex-column justify-content-center justify-content-start mx-auto pt-md-0 pt-5"
        >
          <div>
            <p className="lightTxt">
              {" "}
              <ReactTranslated.Translate text="contenidoWhatwedo" />
            </p>
          </div>
          <div className="pt-3 pb-3">
            <Row>
              <Col>
                <ul className="list-unstyled list-what-we-do">
                  <li>
                    <ReactTranslated.Translate text="titleServices1" />
                  </li>
                  <li>
                    <ReactTranslated.Translate text="titleServices2" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices3" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices4" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices5" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices6" />
                  </li>
                </ul>
              </Col>
              <Col>
                <ul className="list-unstyled list-what-we-do">
                  <li>
                    <ReactTranslated.Translate text="titleServices7" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices8" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices9" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices10" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices11" />
                  </li>{" "}
                  <li>
                    <ReactTranslated.Translate text="titleServices12" />
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="boxWhoWheAre">
        <Col md={6} xs={11} className="d-flex justify-content-center align-content-center align-items-center mx-auto">
          <div>
            {language === "en" ? (
              <img src={howImg} className="img-fluid" />
            ) : (
              <img src={howImg_es} className="img-fluid" />
            )}
          </div>
        </Col>
        <Col
          md={6}
          xs={11}
          className="d-flex flex-column justify-content-center justify-content-start mx-auto pt-md-0 pt-5"
        >
          <div>
            <p className="lightTxt">
              <ReactTranslated.Translate text="contenidoHowwedoit" />
            </p>
          </div>
          <div className="pt-3 pb-3">
            {" "}
            <p>
              <ReactTranslated.Translate text="contenidoHowwedoPresent" />
            </p>
          </div>
          <Row>
            <Col md={3} xs={6} className="text-center pt-4">
              <img src={tanque} className="img-fluid" />
            </Col>
            <Col md={3} xs={6} className="text-center pt-4">
              <img src={avra} className="img-fluid" />
            </Col>
            <Col md={3} xs={6} className="text-center pt-4">
              <img src={venture} className="img-fluid" />
            </Col>
            <Col md={3} xs={6} className="text-center pt-4">
              <img src={tokyo} className="img-fluid" />
            </Col>
          </Row>
          <Row>
            <Col md={3} xs={6} className="text-center pt-4">
              <img src={amblagar} className="img-fluid" />
            </Col>
            <Col md={3} xs={6} className="text-center pt-4">
              <img src={deva} className="img-fluid" />
            </Col>
            <Col md={3} xs={6} className="text-center pt-4">
              <img src={sc} className="img-fluid" />
            </Col>
            <Col md={3} xs={6} className="text-center pt-4">
              <img src={praset} className="img-fluid" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WhoWeAre;
